import React from 'react';
import { ROUTES } from './routeDefinition';
import { Route } from 'react-router-dom';

import ProtectedRoutes from '../components/common/ProtectedRoute';
// const ProtectedRoutes = lazy(() => import('../components/common/ProtectedRoute'));

import PublicRoute from '../components/common/PublicRoutes';

const Routes = (
  <>
    {/* <Route
            path={ROUTES.NEWLAYOUT.PATH}
            element={ROUTES.NEWLAYOUT.ELEMENT}
            errorElement={ROUTES.ERROR_PAGE.ELEMENT}
        >
            <Route index element={ROUTES.NEWHOME.ELEMENT} />
        </Route> */}
    <Route
      path={ROUTES.REGISTER.PATH}
      element={<PublicRoute>{ROUTES.REGISTER.ELEMENT}</PublicRoute>}
      errorElement={ROUTES.ERROR_PAGE.ELEMENT}
    />
    <Route
      path={ROUTES.LOGIN.PATH}
      element={<PublicRoute>{ROUTES.LOGIN.ELEMENT}</PublicRoute>}
      errorElement={ROUTES.ERROR_PAGE.ELEMENT}
    />
    <Route
      path={ROUTES.INIT.PATH}
      element={<PublicRoute>{ROUTES.INIT.ELEMENT}</PublicRoute>}
      errorElement={ROUTES.ERROR_PAGE.ELEMENT}
    />
    <Route
      path={ROUTES.GOOGLE_AUTH_SUCCESS.PATH}
      element={ROUTES.GOOGLE_AUTH_SUCCESS.ELEMENT}
      errorElement={ROUTES.ERROR_PAGE.ELEMENT}
    />
    <Route
      path={ROUTES.FACEBOOK_AUTH_SUCCESS.PATH}
      element={ROUTES.FACEBOOK_AUTH_SUCCESS.ELEMENT}
      errorElement={ROUTES.ERROR_PAGE.ELEMENT}
    />
    <Route
      path={ROUTES.ONBOARDING.PATH}
      element={<ProtectedRoutes>{ROUTES.ONBOARDING.ELEMENT}</ProtectedRoutes>}
    />
    {/* <Route
      path={ROUTES.ONBOARDING_PROFILE.PATH}
      element={
        <ProtectedRoutes>{ROUTES.ONBOARDING_PROFILE.ELEMENT}</ProtectedRoutes>
      }
    />
    
    <Route
      path={ROUTES.ONBOARDING_INTERESTS.PATH}
      element={
        <ProtectedRoutes>{ROUTES.ONBOARDING_INTERESTS.ELEMENT}</ProtectedRoutes>
      }
    /> */}
    {/* New onboarding */}
    <Route
      path={ROUTES.ONBOARDING_USERTYPE.PATH}
      element={
        <ProtectedRoutes>{ROUTES.ONBOARDING_USERTYPE.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.ONBOARDING_OCCUPATION.PATH}
      element={
        <ProtectedRoutes>
          {ROUTES.ONBOARDING_OCCUPATION.ELEMENT}
        </ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.ONBOARDING_SKILLS.PATH}
      element={
        <ProtectedRoutes>{ROUTES.ONBOARDING_SKILLS.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.ONBOARDING_LANGUAGES.PATH}
      element={
        <ProtectedRoutes>{ROUTES.ONBOARDING_LANGUAGES.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.ONBOARDING_BIO.PATH}
      element={
        <ProtectedRoutes>{ROUTES.ONBOARDING_BIO.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.ONBOARDING_SUBSCRIPTIONS.PATH}
      element={
        <ProtectedRoutes>
          {ROUTES.ONBOARDING_SUBSCRIPTIONS.ELEMENT}
        </ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.ONBOARDING_INTERESTS.PATH}
      element={
        <ProtectedRoutes>{ROUTES.ONBOARDING_INTERESTS.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.ONBOARDING_SERVICEINTEREST.PATH}
      element={
        <ProtectedRoutes>
          {ROUTES.ONBOARDING_SERVICEINTEREST.ELEMENT}
        </ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.ONBOARDING_CREATIVETYPES.PATH}
      element={
        <ProtectedRoutes>
          {ROUTES.ONBOARDING_CREATIVETYPES.ELEMENT}
        </ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.ONBOARDING_IMPORT.PATH}
      element={
        <ProtectedRoutes>{ROUTES.ONBOARDING_IMPORT.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.ONBOARDING_IMPORT.PATH}
      element={
        <ProtectedRoutes>{ROUTES.ONBOARDING_IMPORT.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.INTERESTS.PATH}
      element={<ProtectedRoutes>{ROUTES.INTERESTS.ELEMENT}</ProtectedRoutes>}
    />
    <Route
      path={ROUTES.EVENTS_CREATE_PREVIEW.PATH}
      element={ROUTES.EVENTS_CREATE_PREVIEW.ELEMENT}
    />
    <Route
      path={ROUTES.SUGGESTIONS.PATH}
      element={<ProtectedRoutes>{ROUTES.SUGGESTIONS.ELEMENT}</ProtectedRoutes>}
    />
    <Route
      path={ROUTES.INVITE.PATH}
      element={<ProtectedRoutes>{ROUTES.INVITE.ELEMENT}</ProtectedRoutes>}
    />
    <Route
      path={ROUTES.BRAND_INVITE.PATH}
      element={<ProtectedRoutes>{ROUTES.BRAND_INVITE.ELEMENT}</ProtectedRoutes>}
    />
    <Route
      path={ROUTES.EVENT_TICKETS.PATH}
      element={
        <ProtectedRoutes>{ROUTES.EVENT_TICKETS.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.AMAKA_STUDIO.PATH}
      element={<ProtectedRoutes>{ROUTES.AMAKA_STUDIO.ELEMENT}</ProtectedRoutes>}
    >
      <Route
        path={ROUTES.CHANNEL_CUSTOMISATION.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.CHANNEL_CUSTOMISATION.ELEMENT}
          </ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.CHANNEL_MEMBERSHIPS.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.CHANNEL_MEMBERSHIPS.ELEMENT}
          </ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.AMAKA_LOAN.PATH}
        element={<ProtectedRoutes>{ROUTES.AMAKA_LOAN.ELEMENT}</ProtectedRoutes>}
      />
      <Route
        path={ROUTES.EARN.PATH}
        element={<ProtectedRoutes>{ROUTES.EARN.ELEMENT}</ProtectedRoutes>}
      />
      <Route
        path={ROUTES.CHANNEL_CONTENT.PATH}
        element={
          <ProtectedRoutes>{ROUTES.CHANNEL_CONTENT.ELEMENT}</ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.PURCHASES_AND_MEMBERSHIPS.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.PURCHASES_AND_MEMBERSHIPS.ELEMENT}
          </ProtectedRoutes>
        }
      />
    </Route>

    {/* Public Wallet Route */}

    <Route
      path={ROUTES.DASHBOARD.PATH}
      element={<PublicRoute>{ROUTES.DASHBOARD.ELEMENT}</PublicRoute>}
      // element={<Navigate to={ROUTES.DASHBOARD_OVERVIEW_PAGE.PATH} />}
      // element={<Navigate to={ROUTES.DASHBOARD_OVERVIEW_PAGE.PATH} />}
    >
      <Route
        path={ROUTES.DASHBOARD_WALLET_APPLY_PUBLIC.PATH}
        element={ROUTES.DASHBOARD_WALLET_APPLY_PUBLIC.ELEMENT}
      />
    </Route>

    {/* Dashboard */}
    <Route
      path={ROUTES.DASHBOARD.PATH}
      element={<ProtectedRoutes>{ROUTES.DASHBOARD.ELEMENT}</ProtectedRoutes>}
      // element={<Navigate to={ROUTES.DASHBOARD_OVERVIEW_PAGE.PATH} />}
      // element={<Navigate to={ROUTES.DASHBOARD_OVERVIEW_PAGE.PATH} />}
    >
      <Route
        path={ROUTES.DASHBOARD_OVERVIEW_PAGE.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.DASHBOARD_OVERVIEW_PAGE.ELEMENT}
          </ProtectedRoutes>
        }
      >
        <Route
          path={ROUTES.DASHBOARD_OVERVIEW.PATH}
          element={
            <ProtectedRoutes>
              {ROUTES.DASHBOARD_OVERVIEW.ELEMENT}
              {/* {ROUTES.DASHBOARD_OVERVIEW_ENGAGEMENT.ELEMENT} */}
            </ProtectedRoutes>
          }
        />
      </Route>
      <Route
        path={ROUTES.DASHBOARD_SETTINGS.PATH}
        element={
          <ProtectedRoutes>{ROUTES.DASHBOARD_SETTINGS.ELEMENT}</ProtectedRoutes>
        }
      >
        <Route
          path={ROUTES.DASHBOARD_SETTINGS_PROFILE.PATH}
          element={
            <ProtectedRoutes>
              {ROUTES.DASHBOARD_SETTINGS_PROFILE.ELEMENT}
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTES.DASHBOARD_SETTINGS_PAYMENT_ACCOUNT.PATH}
          element={
            <ProtectedRoutes>
              {ROUTES.DASHBOARD_SETTINGS_PAYMENT_ACCOUNT.ELEMENT}
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTES.DASHBOARD_SETTINGS_BILLING.PATH}
          element={
            <ProtectedRoutes>
              {ROUTES.DASHBOARD_SETTINGS_BILLING.ELEMENT}
            </ProtectedRoutes>
          }
        />
      </Route>
      <Route
        path={ROUTES.DASHBOARD_AUDIENCE_SUBSCRIBERS.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.DASHBOARD_AUDIENCE_SUBSCRIBERS.ELEMENT}
          </ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.DASHBOARD_MANAGE_CONTENT.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.DASHBOARD_MANAGE_CONTENT.ELEMENT}
          </ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.DASHBOARD_MONETIZE.PATH}
        element={
          <ProtectedRoutes>{ROUTES.DASHBOARD_MONETIZE.ELEMENT}</ProtectedRoutes>
        }
      />

      <Route
        path={ROUTES.DASHBOARD_WALLET_APPLY.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.DASHBOARD_WALLET_APPLY.ELEMENT}
          </ProtectedRoutes>
        }
      />

      <Route
        path={ROUTES.DASHBOARD_IMPORT_CONTENT.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.DASHBOARD_IMPORT_CONTENT.ELEMENT}
          </ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.DASHBOARD_AUDIENCE_SUBSCRIBERS.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.DASHBOARD_AUDIENCE_SUBSCRIBERS.ELEMENT}
          </ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.DASHBOARD_AUDIENCE_SUBSCRIPTIONS.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.DASHBOARD_AUDIENCE_SUBSCRIPTIONS.ELEMENT}
          </ProtectedRoutes>
        }
      />
      {/*
       <Route
        path={ROUTES.GIG_PROPOSALS.PATH}
        element={
          <ProtectedRoutes>{ROUTES.GIG_PROPOSALS.ELEMENT}</ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.GIG_PROPOSAL_DETAILS.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.GIG_PROPOSAL_DETAILS.ELEMENT}
          </ProtectedRoutes>
        }
      /> */}

      <Route
        path={ROUTES.GIG_CONTRACT.PATH}
        element={
          <ProtectedRoutes>{ROUTES.GIG_CONTRACT.ELEMENT}</ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.GIG_PROPOSALS.PATH}
        element={
          <ProtectedRoutes>{ROUTES.GIG_PROPOSALS.ELEMENT}</ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.GIG_PROPOSAL_DETAILS.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.GIG_PROPOSAL_DETAILS.ELEMENT}
          </ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.DASHBOARD_AUDIENCE_SUBSCRIPTIONS.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.DASHBOARD_AUDIENCE_SUBSCRIPTIONS.ELEMENT}
          </ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.DASHBOARD_MANAGE_CONTENT.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.DASHBOARD_MANAGE_CONTENT.ELEMENT}
          </ProtectedRoutes>
        }
      />
    </Route>
    {/*
       <Route
        path={ROUTES.GIG_PROPOSALS.PATH}
        element={
          <ProtectedRoutes>{ROUTES.GIG_PROPOSALS.ELEMENT}</ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.GIG_PROPOSAL_DETAILS.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.GIG_PROPOSAL_DETAILS.ELEMENT}
          </ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.GIG_PROPOSALS.PATH}
        element={
          <ProtectedRoutes>{ROUTES.GIG_PROPOSALS.ELEMENT}</ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.GIG_PROPOSAL_DETAILS.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.GIG_PROPOSAL_DETAILS.ELEMENT}
          </ProtectedRoutes>
        }
      />
      
    </Route>

    <Route
      path={ROUTES.CREATE_ARTICLE.PATH}
      element={
        <ProtectedRoutes>{ROUTES.CREATE_ARTICLE.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.CREATE_ARTICLE.PATH}
      element={
        <ProtectedRoutes>{ROUTES.CREATE_ARTICLE.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.CREATE_VIDEO.PATH}
      element={<ProtectedRoutes>{ROUTES.CREATE_VIDEO.ELEMENT}</ProtectedRoutes>}
    />
    <Route
      path={ROUTES.CREATE_VIDEO.PATH}
      element={<ProtectedRoutes>{ROUTES.CREATE_VIDEO.ELEMENT}</ProtectedRoutes>}
    />
    <Route
      path={ROUTES.EVENTS_CREATE.PATH}
      element={
        <ProtectedRoutes>{ROUTES.EVENTS_CREATE.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.GIGS_CREATE.PATH}
      element={<ProtectedRoutes>{ROUTES.GIGS_CREATE.ELEMENT}</ProtectedRoutes>}
    />
    <Route
      path={ROUTES.GIGS_APPLY_CREATE_BRIEF.PATH}
      element={
        <ProtectedRoutes>
          {ROUTES.GIGS_APPLY_CREATE_BRIEF.ELEMENT}
        </ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.GIGS_CREATE_PREVIEW.PATH}
      element={
        <ProtectedRoutes>{ROUTES.GIGS_CREATE_PREVIEW.ELEMENT}</ProtectedRoutes>
      }
    />

    <Route
            path={ROUTES.EVENTS_CREATE.PATH}
            element={
                <ProtectedRoutes>
                    {ROUTES.EVENTS_CREATE.ELEMENT}
                </ProtectedRoutes>
            }
        /> */}
    <Route
      path={ROUTES.UPLOAD_VIDEO.PATH}
      element={<ProtectedRoutes>{ROUTES.UPLOAD_VIDEO.ELEMENT}</ProtectedRoutes>}
    />
    <Route
      path={ROUTES.PREVIEW_ARTICLE.PATH}
      element={
        <ProtectedRoutes>{ROUTES.PREVIEW_ARTICLE.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.PREVIEW_EVENT.PATH}
      element={
        <ProtectedRoutes>{ROUTES.PREVIEW_EVENT.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.ARTICLE_CONTENT.PATH}
      element={
        <ProtectedRoutes>{ROUTES.ARTICLE_CONTENT.ELEMENT}</ProtectedRoutes>
      }
    />
    <Route
      path={ROUTES.SUSBCRIPTION_PLANS.PATH}
      element={<>{ROUTES.SUSBCRIPTION_PLANS.ELEMENT}</>}
    />
    <Route
      path={ROUTES.RESET_PASSWORD.PATH}
      element={ROUTES.RESET_PASSWORD.ELEMENT}
    />
    <Route
      path={ROUTES.FORGOT_PASSWORD.PATH}
      element={ROUTES.FORGOT_PASSWORD.ELEMENT}
    />
    <Route
      path={ROUTES.ONLINE_EVENT.PATH}
      element={ROUTES.ONLINE_EVENT.ELEMENT}
    />
    <Route path={ROUTES.EVENT_ROOM.PATH} element={ROUTES.EVENT_ROOM.ELEMENT} />
    <Route
      path={ROUTES.LAYOUT.PATH}
      element={ROUTES.LAYOUT.ELEMENT}
      errorElement={ROUTES.ERROR_PAGE.ELEMENT}
    >
      <Route index element={ROUTES.HOME.ELEMENT} />
      <Route path={ROUTES.SEARCH.PATH} element={ROUTES.SEARCH.ELEMENT} />
      <Route path={ROUTES.MESSAGES.PATH} element={ROUTES.MESSAGES.ELEMENT} />
      <Route
        path={ROUTES.CHAT_MESSAGES.PATH}
        element={ROUTES.CHAT_MESSAGES.ELEMENT}
      />
      <Route
        path={ROUTES.CHAT_PROFILE.PATH}
        element={ROUTES.CHAT_PROFILE.ELEMENT}
      />
      <Route
        path={ROUTES.FEEDS.PATH}
        element={<PublicRoute>{ROUTES.FEEDS.ELEMENT}</PublicRoute>}
      />
      <Route path={ROUTES.GIGS_HOME.PATH} element={ROUTES.GIGS_HOME.ELEMENT} />
      <Route
        path={ROUTES.USER_PROFILE.PATH}
        element={ROUTES.USER_PROFILE.ELEMENT}
      />
      <Route
        path={ROUTES.GIGS_HOME.PATH}
        element={<ProtectedRoutes>{ROUTES.GIGS_HOME.ELEMENT}</ProtectedRoutes>}
      />
      <Route
        path={ROUTES.SUBSCRIBERS_PAGE.PATH}
        element={
          <ProtectedRoutes>{ROUTES.SUBSCRIBERS_PAGE.ELEMENT}</ProtectedRoutes>
        }
      />
      <Route path={ROUTES.FILTER.PATH} element={ROUTES.FILTER.ELEMENT} />
      <Route
        path={ROUTES.CHALLENGES.PATH}
        element={ROUTES.CHALLENGES.ELEMENT}
      />
      <Route
        path={ROUTES.CHALLENGES_SINGLE.PATH}
        element={ROUTES.CHALLENGES_SINGLE.ELEMENT}
      />
      <Route path={ROUTES.EVENTS.PATH} element={ROUTES.EVENTS.ELEMENT} />
      <Route path={ROUTES.MESSAGES.PATH} element={ROUTES.MESSAGES.ELEMENT} />
      <Route
        path={ROUTES.EVENTS_SINGLE.PATH}
        element={ROUTES.EVENTS_SINGLE.ELEMENT}
      />
      <Route
        path={ROUTES.EVENTS_SINGLE_CUSTOM.PATH}
        element={ROUTES.EVENTS_SINGLE_CUSTOM.ELEMENT}
      />
      <Route
        path={ROUTES.UPCOMING_EVENTS.PATH}
        element={ROUTES.UPCOMING_EVENTS.ELEMENT}
      />
      <Route path={ROUTES.ARTICLE.PATH} element={ROUTES.ARTICLE.ELEMENT} />
      <Route
        path={ROUTES.VIDEO_ARTICLE.PATH}
        element={ROUTES.VIDEO_ARTICLE.ELEMENT}
      />
      <Route path={ROUTES.CONTENT.PATH} element={ROUTES.CONTENT.ELEMENT} />
      <Route
        path={ROUTES.CONTENT.ALTERNATIVE_PATH}
        element={ROUTES.CONTENT.ELEMENT}
      />
      <Route path={ROUTES.EVENTS.PATH} element={ROUTES.EVENTS.ELEMENT} />
      <Route
        path={ROUTES.GIGS_SINGLE_DETAILS.PATH}
        element={ROUTES.GIGS_SINGLE_DETAILS.ELEMENT}
      />
      <Route
        path={ROUTES.GIGS_SINGLE_DETAILS.ALTERNATIVE_PATH}
        element={ROUTES.GIGS_SINGLE_DETAILS.ELEMENT}
      />

      <Route
        path={ROUTES.GIGS_CREATE.PATH}
        element={
          <ProtectedRoutes>{ROUTES.GIGS_CREATE.ELEMENT}</ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.GIGS_APPLY_CREATE_BRIEF.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.GIGS_APPLY_CREATE_BRIEF.ELEMENT}
          </ProtectedRoutes>
        }
      />
      <Route
        path={ROUTES.GIGS_CREATE_PREVIEW.PATH}
        element={ROUTES.GIGS_CREATE_PREVIEW.ELEMENT}
      />

      <Route
        path={ROUTES.GIGS_INVITE_CREATOR.PATH}
        element={
          <ProtectedRoutes>
            {ROUTES.GIGS_INVITE_CREATOR.ELEMENT}
          </ProtectedRoutes>
        }
      />
    </Route>
  </>
);

export default Routes;
