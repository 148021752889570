import React, { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import {
  DialogStack,
  // StyledAlert,
  StyledCloseIcon,
  StyledDialog,
  StyledDialogContentText,
  StyledDialogTitle,
} from './styles';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import { StyledTextField } from '../ImportDialog/styles';
import { Button } from '@mui/material';
import { toast } from 'sonner';
// import { SegmentTrack } from '../../../SegmentAnalytics';
// import useAuth from '../../../../hooks/useAuth';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  link: string;
  segmentTrackData?: any;
  isReferral?: boolean;
}

export const CopyLinkDialog: React.FC<Props> = ({
  isOpen,
  isReferral = true,
  onClose,
  title,
  description,
  link,
  // segmentTrackData,
}) => {
  const [copied, setCopied] = useState<boolean>(false);
  // const { userInfo } = useAuth();
  const referralLink = link + '&utm_source=Referral Link';

  const url = isReferral ? referralLink : link;

  const share = () => {
    // console.log(navigator.share);
    // if (navigator.share) {
    //   navigator
    //     .share({
    //       title: 'Amaka Studio',
    //       url: url,
    //     })
    //     .then(() => {
    //       console.log('Thanks for sharing!');
    //       SegmentTrack({
    //         name: 'import_subscribers',
    //         properties: {
    //           userId: userInfo.uid,
    //           source: 'copy_link',
    //           link: referralLink,
    //           date_created: new Date().toISOString(),
    //           status: 'success',
    //         },
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // } else {
    //   navigator?.clipboard?.writeText(url).then(() => setCopied(true));
    // }
    navigator?.clipboard?.writeText(url).then(() => setCopied(true));
    // if (segmentTrackData) SegmentTrack(segmentTrackData);
  };

  return (
    <>
      <StyledDialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={onClose}
      >
        <StyledCloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{ color: '#ff0035' }}
        >
          <CloseIcon />
        </StyledCloseIcon>
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <DialogContent sx={{ color: 'black', padding: 0 }}>
          <StyledDialogContentText>{description}</StyledDialogContentText>

          <DialogStack direction={'row'} spacing={3}>
            <StyledTextField
              sx={{ padding: 0 }}
              id="outlined-multiline-static"
              value={link}
            />
            <Button
              variant={'contained'}
              size="small"
              sx={{
                padding: '0.5rem 1rem',
                width: 'fit-content',
                textWrap: 'nowrap',
              }}
              color="primary"
              onClick={() => {
                share();
                setTimeout(() => {
                  setCopied(false);
                }, 800);
              }}
            >
              Copy Link
            </Button>
          </DialogStack>
        </DialogContent>
      </StyledDialog>
      {copied && toast.success('Link Copied')}
    </>
  );
};
