import { Navigate, useLocation } from 'react-router';
import { ROUTES } from '../../../constants/routeDefinition';
import {
  getValueFromLocalStore,
  setValueInLocalStore,
} from '../../../utils/localStorage';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { STORAGE_KEYS } from '../../../constants/storage';
import { usePostHog, useFeatureFlagVariantKey } from 'posthog-js/react';
import Loader from '../../common/Loader';

const ProtectedRoutes = ({ children }: any) => {
  const isUserLoggedIn = getValueFromLocalStore(STORAGE_KEYS.IS_USER_LOGGED_IN);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const posthog = usePostHog();
  const variant = useFeatureFlagVariantKey('amaka-grants-conversion');
  const [flagsLoaded, setFlagsLoaded] = useState(false);

  useEffect(() => {
    if (posthog) {
      posthog.onFeatureFlags(() => {
        setFlagsLoaded(true);
      });
    }
  }, [posthog]);

  useEffect(() => {
    if (flagsLoaded) {
      console.log('Feature flag variant:', variant);
    }
  }, [flagsLoaded, variant, posthog]);

  const referralCode = (
    searchParams.get(STORAGE_KEYS.REFERRAL_CODE) || ''
  ).toString();
  const ref = (searchParams.get('ref') || '').toString();

  useEffect(() => {
    handleReferrals();
  }, []);

  const handleReferrals = () => {
    if (referralCode) {
      setValueInLocalStore(STORAGE_KEYS.REFERRAL_CODE, referralCode);
    }
    if (ref) {
      setValueInLocalStore(STORAGE_KEYS.USERAMK_REFERRAL_CODE, ref);
    }
  };

  if (!flagsLoaded) {
    return (
      <div>
        <Loader open={true} />
      </div>
    ); // Or any loading indicator
  }

  if (!isUserLoggedIn) {
    if (location.pathname.includes('/dashboard/wallet/apply')) {
      // console.log(variant);
      if (variant === 'test') {
        return (
          <Navigate to={ROUTES.DASHBOARD_WALLET_APPLY_PUBLIC.PATH} replace />
        );
      }
    }
    const redirectFrom = location.pathname + location.search;

    return (
      <Navigate
        to={ROUTES.LOGIN.PATH}
        replace
        state={{
          redirect: redirectFrom,
          redirectFrom: redirectFrom,
        }}
      />
    );
  }
  return children;
};

export default ProtectedRoutes;
