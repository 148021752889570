import React, { lazy, Suspense } from 'react';
import { AUTH_FORM, CONTENT_TYPE } from '.';
const Interests = lazy(() => import('../pages/Interests'));
const Suggestions = lazy(() => import('../pages/Suggestions'));
const PreviewContent = lazy(() => import('../pages/PreviewContent'));
const Invitation = lazy(() => import('../pages/Invitation'));
const BrandInvitation = lazy(() => import('../pages/BrandReferral'));
const NewLayout = lazy(() => import('../components/NewLayout'));
const NewHome = lazy(() => import('../pages/NewHome'));
const ChannelCustomisation = lazy(
  () => import('../components/ChannelCustomisation'),
);
const AmakaStudio = lazy(() => import('../pages/AmakaStudio'));
const ChannelMemberships = lazy(
  () => import('../components/Earn/ChannelMemberships'),
);
const AmakaLoan = lazy(() => import('../components/Earn/AmakaLoan'));
const Authentication = lazy(() => import('../pages/NewAuthentication'));
const SocialAuthSuccess = lazy(
  () => import('../pages/NewAuthentication/SocialAuthSuccess'),
);
const SubscriptionPlans = lazy(() => import('../pages/SubscriptionPlans'));
const ChannelContent = lazy(() => import('../components/ChannelContent'));
const ImportUserArticle = lazy(() => import('../pages/ImportUserArticle'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const SingleEventContent = lazy(
  () => import('../components/Event/SingleEventContent'),
);
const Tickets = lazy(() => import('../pages/EventTicketPage'));
const ManageSubscriptions = lazy(
  () => import('../components/Earn/ManageSubscription'),
);
const EventCreate = lazy(() => import('../components/EventCreate'));
const EventCreatePreview = lazy(
  () => import('../components/EventCreate/components/EventCreatePreview'),
);

const Events = lazy(() => import('../pages/Events'));
const Messages = lazy(() => import('../pages/Messages'));
const Search = lazy(() => import('../pages/Search'));
const ChatMessages = lazy(() => import('../pages/Messages/chat'));
const ChatProfile = lazy(() => import('../pages/Messages/profile'));
const EVENTROOM = lazy(() => import('../components/Event/Online/Room'));
const Earn = lazy(() => import('../components/Earn'));
const Challenge = lazy(() => import('../pages/Challenge'));
const OnboardingProfile = lazy(() => import('../pages/OnboardingProfile'));
const PurchasesAndMemberships = lazy(
  () => import('../components/PurchasesAndMemberships'),
);
const ErrorPage = lazy(() => import('../pages/ErrorPage'));
const Filter = lazy(() => import('../pages/Filter'));
const UpcomingEvents = lazy(() => import('../pages/UpcomingEvents'));
const UserProfile = lazy(() => import('../pages/UserProfile'));
const Content = lazy(() => import('../pages/Content'));
const ViewContentPage = lazy(() => import('../pages/ViewContentPage'));
const ContentEditor = lazy(() => import('../components/ContentEditor'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const DashboardSettings = lazy(
  () => import('../pages/Dashboard/Pages/Settings'),
);
const DashboardContent = lazy(() => import('../pages/Dashboard/Pages/Content'));
const DashboardWallet = lazy(() => import('../pages/Dashboard/Pages/Wallet'));
const DashboardProfileSettings = lazy(
  () => import('../components/Dashboard/Settings/Profile'),
);
const DashboardBilling = lazy(
  () => import('../components/Dashboard/Settings/Billing'),
);
const DashboardPaymentAccount = lazy(
  () => import('../components/Dashboard/Settings/PaymentAccount'),
);
const DashboardMonetize = lazy(
  () => import('../pages/Dashboard/Pages/Monetize'),
);

const DashboardOverviewAudience = lazy(
  () => import('../components/Dashboard/Overview/Audience'),
);

const GigsHome = lazy(() => import('../pages/GigsHome'));
const GigsCreate = lazy(() => import('../pages/GigsCreate'));
const GigsCreatePreview = lazy(() => import('../pages/GigPreview'));
const SingleGigContent = lazy(
  () => import('../components/GigsCreate/SingleGigContent'),
);

const GigsInviteCreator = lazy(() => import('../pages/GigsInviteCreator'));

//
const GigProposals = lazy(
  () => import('../pages/Dashboard/Pages/Content/Proposals'),
);

const GigContract = lazy(
  () => import('../pages/Dashboard/Pages/Content/Contract/'),
);

const GigProposalDetails = lazy(
  () => import('../pages/Dashboard/Pages/Content/Proposals/ProposalDetails'),
);

// const SplitLayout = lazy(() => import('../components/Split/SplitLayout'));
import { DashboardAudience } from '../components/Dashboard/Audience';
import { CONTENT_TYPES } from '../components/CreateContentPrompt/constants';
import { SOCIAL_AUTH_PROVIDER } from './auth';
import { ImportContent } from '../components/Dashboard/Content/ImportContent';

// const SplitLayout = lazy(() => import('../components/Split/SplitLayout'));
import DashboardAudienceSubscriptions from '../components/Dashboard/Audience/DashboardAudienceSubscriptions';
const OnboardingFlow = lazy(() => import('../pages/Onboarding'));
const CreateBrief = lazy(
  () => import('../components/GigsCreate/GigApplication/CreateBrief'),
);

// import { Video } from '../pages/ViewContentPage/Video';

const DashboardOverviewPage = lazy(
  () => import('../pages/Dashboard/Pages/Overview'),
);
const DashboardOverview = lazy(
  () => import('../components/Dashboard/Overview/index'),
);

const DashboardEngagement = lazy(
  () => import('../components/Dashboard/Overview/Engagement'),
);

const SubscribersPage = lazy(
  () => import('../components/UserProfile/SubscribersPage'),
);
export const ROUTES = {
  ERROR_PAGE: {
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ErrorPage />
      </Suspense>
    ),
  },
  LAYOUT: {
    PATH: '/',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <NewLayout />
      </Suspense>
    ),
  },
  FEEDS: {
    PATH: '/feed',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <NewHome />
      </Suspense>
    ),
  },
  NEWLAYOUT: {
    PATH: '/newhome',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <NewLayout />
      </Suspense>
    ),
  },
  HOME: {
    ELEMENT: (
      <Suspense fallback={<></>}>
        <NewHome />
      </Suspense>
    ),
    // ELEMENT: <Suspense fallback={<></>}><Home /></Suspense>,
  },
  NEWHOME: {
    ELEMENT: (
      <Suspense fallback={<></>}>
        <NewHome />
      </Suspense>
    ),
  },
  // CREATE_ARTICLE: {
  //     PATH: '/article/basic-info',

  //     ELEMENT: (
  //         <Suspense fallback={<></>}>
  //             <Content formType={CONTENT_TYPE.ARTICLE} />
  //         </Suspense>
  //     ),
  // },
  CREATE_ARTICLE: {
    PATH: '/article/create',

    ELEMENT: (
      <Suspense fallback={<></>}>
        <Content formType={CONTENT_TYPE.ARTICLE} />
      </Suspense>
    ),
  },
  CREATE_VIDEO: {
    PATH: '/video-article/basic-info',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Content formType={CONTENT_TYPE.VIDEO} />
      </Suspense>
    ),
  },
  UPLOAD_VIDEO: {
    PATH: '/video-article/content',
    // ELEMENT: <Upload />,
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ContentEditor articleType={CONTENT_TYPES[2]} />
      </Suspense>
    ),
  },
  PREVIEW_ARTICLE: {
    PATH: '/article/preview',
    ELEMENT: (
      <Suspense fallback={<></>}>
        {/* <ViewContent /> */}
        <PreviewContent isEvent={false} />
      </Suspense>
    ),
  },
  PREVIEW_EVENT: {
    PATH: '/event/preview',
    ELEMENT: (
      <Suspense fallback={<></>}>
        {/* <ViewContent /> */}
        <PreviewContent isEvent={true} />
      </Suspense>
    ),
  },
  ARTICLE_CONTENT: {
    PATH: '/article/content',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ContentEditor />
      </Suspense>
    ),
  },

  REGISTER: {
    PATH: '/register',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Authentication formType={AUTH_FORM.REGISTER} />
      </Suspense>
    ),
  },
  INIT: {
    PATH: '/init',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Authentication formType={AUTH_FORM.INIT} />
      </Suspense>
    ),
  },
  ONBOARDING: {
    PATH: '/basic-information',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Authentication formType={AUTH_FORM.ONBOARDING} />
      </Suspense>
    ),
  },
  ONBOARDING_PROFILE: {
    PATH: '/onboarding-profile',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <OnboardingProfile />
      </Suspense>
    ),
  },
  // ONBOARDING_INTERESTS: {
  //   PATH: '/onboarding-interests',
  //   ELEMENT: (
  //     <Suspense fallback={<></>}>
  //       <OnboardingInterests />
  //     </Suspense>
  //   ),
  // },

  // New onboarding
  ONBOARDING_USERTYPE: {
    PATH: '/onboarding-usertype',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <OnboardingFlow stage="usertype" />
      </Suspense>
    ),
  },

  ONBOARDING_OCCUPATION: {
    PATH: '/onboarding-occupation',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <OnboardingFlow stage="occupation" />
      </Suspense>
    ),
  },

  ONBOARDING_SKILLS: {
    PATH: '/onboarding-skills',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <OnboardingFlow stage="skills" />
      </Suspense>
    ),
  },

  ONBOARDING_LANGUAGES: {
    PATH: '/onboarding-languages',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <OnboardingFlow stage="languages" />
      </Suspense>
    ),
  },

  ONBOARDING_BIO: {
    PATH: '/onboarding-bio',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <OnboardingFlow stage="bio" />
      </Suspense>
    ),
  },

  ONBOARDING_SUBSCRIPTIONS: {
    PATH: '/onboarding-subscriptions',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <OnboardingFlow stage="subscriptions" />
      </Suspense>
    ),
  },

  ONBOARDING_INTERESTS: {
    PATH: '/onboarding-interests',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <OnboardingFlow stage="interests" />
      </Suspense>
    ),
  },

  ONBOARDING_CREATIVETYPES: {
    PATH: '/onboarding-creative-types',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <OnboardingFlow stage="creative-types" />
      </Suspense>
    ),
  },

  ONBOARDING_SERVICEINTEREST: {
    PATH: '/onboarding-service-interests',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <OnboardingFlow stage="service-interests" />
      </Suspense>
    ),
  },

  LOGIN: {
    PATH: '/login',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Authentication formType={AUTH_FORM.LOGIN} />
      </Suspense>
    ),
  },
  GOOGLE_AUTH_SUCCESS: {
    PATH: '/google-auth-success',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <SocialAuthSuccess provider={SOCIAL_AUTH_PROVIDER.GOOGLE} />
      </Suspense>
    ),
  },
  FACEBOOK_AUTH_SUCCESS: {
    PATH: '/facebook-auth-success',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <SocialAuthSuccess provider={SOCIAL_AUTH_PROVIDER.FACEBOOK} />
      </Suspense>
    ),
  },
  APPLE_AUTH_SUCCESS: {
    PATH: '/apple-auth-success',
    ELEMENT: (
      <Suspense fallback={<></>}>
        {' '}
        <SocialAuthSuccess provider={SOCIAL_AUTH_PROVIDER.APPLE} />
      </Suspense>
    ),
  },
  USER_PROFILE: {
    BASE_PATH: '/',
    PATH: '/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <UserProfile />
      </Suspense>
    ),
  },
  AMAKA_STUDIO: {
    PATH: '/amaka-studio',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <AmakaStudio />
      </Suspense>
    ),
  },
  DASHBOARD: {
    PATH: '/dashboard',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Dashboard />
      </Suspense>
    ),
  },
  DASHBOARD_OVERVIEW_PAGE: {
    PATH: '/dashboard/overview',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardOverviewPage />
      </Suspense>
    ),
  },
  DASHBOARD_WALLET_APPLY: {
    PATH: '/dashboard/wallet/apply',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardWallet />
      </Suspense>
    ),
  },
  DASHBOARD_WALLET_APPLY_PUBLIC: {
    PATH: '/dashboard/grant/apply',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardWallet />
      </Suspense>
    ),
  },
  DASHBOARD_OVERVIEW: {
    PATH: '',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardOverview />
      </Suspense>
    ),
  },
  DASHBOARD_OVERVIEW_AUDIENCE: {
    PATH: '/dashboard/overview/audience',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardOverviewAudience />
      </Suspense>
    ),
  },
  DASHBOARD_OVERVIEW_ENGAGEMENT: {
    PATH: '/dashboard/overview/engagement',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardEngagement />
      </Suspense>
    ),
  },
  DASHBOARD_MANAGE_CONTENT: {
    PATH: '/dashboard/content/manage',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardContent />
      </Suspense>
    ),
  },
  GIG_PROPOSALS: {
    PATH: '/dashboard/content/manage/gig-proposals/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <GigProposals />
      </Suspense>
    ),
  },

  GIG_CONTRACT: {
    PATH: '/dashboard/content/manage/gig-contract/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <GigContract />
      </Suspense>
    ),
  },
  GIG_PROPOSAL_DETAILS: {
    PATH: '/dashboard/content/manage/gig-proposals/:id/details/:detailsId',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <GigProposalDetails />
      </Suspense>
    ),
  },
  DASHBOARD_IMPORT_CONTENT: {
    PATH: '/dashboard/content/import',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ImportContent />
      </Suspense>
    ),
  },
  DASHBOARD_SETTINGS: {
    PATH: '/dashboard/settings',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardSettings />
      </Suspense>
    ),
  },
  DASHBOARD_SETTINGS_PROFILE: {
    PATH: '/dashboard/settings/profile',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardProfileSettings />
      </Suspense>
    ),
  },
  DASHBOARD_SETTINGS_BILLING: {
    PATH: '/dashboard/settings/billing',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardBilling />{' '}
      </Suspense>
    ),
  },
  // DASHBOARD_AUDIENCE: {
  //     PATH: '/dashboard/audience',
  //     ELEMENT: (
  //         <Suspense fallback={<></>}>
  //             <DashboardAudience />
  //         </Suspense>
  //     ),
  // },
  DASHBOARD_SETTINGS_PAYMENT_ACCOUNT: {
    PATH: '/dashboard/settings/payment-accounts',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardPaymentAccount />
      </Suspense>
    ),
  },
  DASHBOARD_AUDIENCE_SUBSCRIBERS: {
    PATH: '/dashboard/audience/subscribers',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardAudience />
      </Suspense>
    ),
  },
  DASHBOARD_AUDIENCE_SUBSCRIPTIONS: {
    PATH: '/dashboard/audience/subscriptions',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardAudienceSubscriptions />
      </Suspense>
    ),
  },
  DASHBOARD_MONETIZE: {
    PATH: '/dashboard/monetize',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <DashboardMonetize />
      </Suspense>
    ),
  },
  FILTER: {
    PATH: '/filter',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Filter />
      </Suspense>
    ),
  },
  ARTICLE: {
    PATH: '/article',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ViewContentPage />
      </Suspense>
    ),
  },

  VIDEO_ARTICLE: {
    PATH: '/video-article',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ViewContentPage />
      </Suspense>
    ),
  },
  CONTENT: {
    BASE_PATH: '/content',
    PATH: '/content/:id/:titleSlug',
    ALTERNATIVE_PATH: '/content/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ViewContentPage />
      </Suspense>
    ),
  },
  CHANNEL_CUSTOMISATION: {
    PATH: '/amaka-studio/channel-customisation',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ChannelCustomisation />
      </Suspense>
    ),
  },
  SUSBCRIPTION_PLANS: {
    BASE_PATH: '/subscribe',
    PATH: '/subscribe/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <SubscriptionPlans />
      </Suspense>
    ),
  },
  SUBSCRIBERS_PAGE: {
    BASE_PATH: '/subscribers',
    PATH: '/subscribers/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <SubscribersPage />
      </Suspense>
    ),
  },
  EARN: {
    PATH: '/amaka-studio/earn',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Earn />
      </Suspense>
    ),
  },
  CHANNEL_MEMBERSHIPS: {
    PATH: '/amaka-studio/earn/channel-memberships',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ChannelMemberships />
      </Suspense>
    ),
  },
  AMAKA_LOAN: {
    PATH: '/amaka-studio/earn/amaka-loan',
    ELEMENT: (
      <Suspense fallback={<></>}>
        {' '}
        <AmakaLoan />
      </Suspense>
    ),
  },
  CHANNEL_CONTENT: {
    PATH: '/amaka-studio/content',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ChannelContent />
      </Suspense>
    ),
  },
  PURCHASES_AND_MEMBERSHIPS: {
    PATH: '/amaka-studio/purchases-and-memberships',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <PurchasesAndMemberships />
      </Suspense>
    ),
  },
  MANAGE_SUBSCRIPTION: {
    PATH: '/amaka-studio/manage-subscriptions',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ManageSubscriptions />
      </Suspense>
    ),
  },
  ONBOARDING_IMPORT: {
    PATH: '/onboarding-import',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ImportUserArticle />
      </Suspense>
    ),
  },
  INTERESTS: {
    PATH: '/interests',
    ELEMENT: (
      <Suspense fallback={<></>}>
        {' '}
        <Interests />
      </Suspense>
    ),
  },
  SUGGESTIONS: {
    PATH: '/suggestions',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Suggestions />{' '}
      </Suspense>
    ),
  },
  INVITE: {
    PATH: '/invite',
    ELEMENT: (
      <Suspense fallback={<></>}>
        {' '}
        <Invitation />
      </Suspense>
    ),
  },
  BRAND_INVITE: {
    PATH: '/brand_invite',
    ELEMENT: (
      <Suspense fallback={<></>}>
        {' '}
        <BrandInvitation />
      </Suspense>
    ),
  },
  RESET_PASSWORD: {
    PATH: '/reset-password',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ResetPassword formType={AUTH_FORM.RESET} />
      </Suspense>
    ),
  },
  FORGOT_PASSWORD: {
    PATH: '/forgot-password',
    // ELEMENT: <ForgotPassword formType={AUTH_FORM.FORGOT} />,
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Authentication formType={AUTH_FORM.FORGOT} />
      </Suspense>
    ),
  },
  CHALLENGES: {
    PATH: '/grants',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Challenge />
      </Suspense>
    ),
  },
  CHALLENGES_SINGLE: {
    BASE_PATH: '/grants',
    PATH: '/grants/:id/:title',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Challenge />
      </Suspense>
    ),
  },
  EVENTS: {
    PATH: '/events',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Events />
      </Suspense>
    ),
  },
  SEARCH: {
    PATH: '/search',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Search />
      </Suspense>
    ),
  },
  MESSAGES: {
    PATH: '/messages',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <Messages />
      </Suspense>
    ),
  },

  CHAT_MESSAGES: {
    PATH: '/messages/:chatRoomId/:userId',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ChatMessages />
      </Suspense>
    ),
  },
  CHAT_PROFILE: {
    PATH: '/messages/:chatRoomId/:userId/profile',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <ChatProfile />
      </Suspense>
    ),
  },
  ONLINE_EVENT: {
    PATH: '/online-event',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <EVENTROOM />
      </Suspense>
    ),
  },
  EVENT_ROOM: {
    PATH: '/room/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <EVENTROOM />
      </Suspense>
    ),
  },
  EVENT_TICKETS: {
    PATH: '/events/:id/buy-a-ticket',
    ELEMENT: (
      <Suspense fallback={<></>}>
        {' '}
        <Tickets />
      </Suspense>
    ),
  },
  EVENTS_SINGLE: {
    BASE_PATH: '/e',
    PATH: '/e/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        {' '}
        <SingleEventContent />
      </Suspense>
    ),
  },
  EVENTS_SINGLE_CUSTOM: {
    BASE_PATH: '/event',
    PATH: '/event/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        {' '}
        <SingleEventContent />
      </Suspense>
    ),
  },
  EVENTS_CREATE: {
    BASE_PATH: '/event',
    PATH: '/event/create',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <EventCreate />
      </Suspense>
    ),
  },
  EVENTS_CREATE_PREVIEW: {
    BASE_PATH: '/event/create',
    PATH: '/event/create/preview/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <EventCreatePreview />
      </Suspense>
    ),
  },
  UPCOMING_EVENTS: {
    PATH: '/upcoming-events',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <UpcomingEvents />
      </Suspense>
    ),
  },
  GIGS_HOME: {
    PATH: '/gigs',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <GigsHome />
      </Suspense>
    ),
  },
  GIGS_CREATE: {
    PATH: '/gig/content',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <GigsCreate />
      </Suspense>
    ),
  },
  GIGS_APPLY_CREATE_BRIEF: {
    PATH: '/gig/apply/brief',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <CreateBrief />
      </Suspense>
    ),
  },
  GIGS_CREATE_PREVIEW: {
    BASE_PATH: '/gig/content',
    PATH: '/gig/content/preview/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <GigsCreatePreview />
      </Suspense>
    ),
  },
  GIGS_SINGLE_DETAILS: {
    BASE_PATH: '/gig',
    PATH: '/gig/:id/:slug',
    ALTERNATIVE_PATH: '/gig/:id',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <SingleGigContent />
      </Suspense>
    ),
  },
  GIGS_INVITE_CREATOR: {
    BASE_PATH: '/gig/content',
    PATH: '/gig/content/:id/invite-creator',
    ELEMENT: (
      <Suspense fallback={<></>}>
        <GigsInviteCreator />
      </Suspense>
    ),
  },
};
