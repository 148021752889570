import React, { useEffect, useState } from 'react';
import useAuth from '../../../../hooks/useAuth';
import { GetSubscriberResponse, SubscriberData } from './types';
import {
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { StyledIconButton } from './styles';
import AlertDialog from '../../../common/AlertDialog';
import { ForwardArrowIcon } from '../../../../assets/icons/ForwadArrowIcon';
import { BackArrowIcon } from '../../../../assets/icons/BackArrow';
import TableHeader from './TableHeader';
import { CustomTableRow } from './TableRow';
import { CopyLinkDialog } from '../CopyLinkDialog/Index';
import { getAllUserSubscribers } from '../apis';
import InputBase from '@mui/material/InputBase';

interface iProps {
  isMobile: boolean;
}

const PAGE_SIZE = 1000;

const SubscriberFetcher: React.FC<iProps> = ({ isMobile }) => {
  const [subscribers, setSubscribers] = useState<SubscriberData[]>([]);
  const { userInfo } = useAuth();

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const data = (await getAllUserSubscribers(
          +userInfo.id,
          0,
          PAGE_SIZE,
        )) as GetSubscriberResponse;
        setSubscribers(data.data);
      } catch (error) {
        console.error('Error fetching subscribers:', error);
      }
    };

    fetchSubscribers();
  }, [userInfo.id]);

  return <SubscribersTable subscribers={subscribers} isMobile={isMobile} />;
};

export default SubscriberFetcher;

interface Props {
  subscribers: SubscriberData[];
  isMobile: boolean;
}

const SubscribersTable: React.FC<Props> = ({ subscribers, isMobile }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [link, setLink] = useState<string>('');

  const [inputPage, setInputPage] = useState(page + 1);

  useEffect(() => {
    console.log(subscribers);
  }, [subscribers]);

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      const pageNumber = parseInt(value, 10);
      if (pageNumber >= 1 && pageNumber <= pageCount) {
        setInputPage(pageNumber);
        handleChangePage(null, pageNumber - 1);
      } else if (pageNumber < 1) {
        setInputPage(1);
        handleChangePage(null, pageNumber);
      } else if (pageNumber > pageCount) {
        setInputPage(pageCount);
        handleChangePage(null, pageCount);
      }
    }
  };

  const handleInputKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      const pageNumber = inputPage;
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= pageCount) {
        handleChangePage(null, pageNumber - 1);
      }
    }
  };

  const [dialog, setDialog] = useState<{
    open: boolean;
    title: string;
    description: string;
    response: boolean;
    status?: string;
  }>({
    open: false,
    title: '',
    description: '',
    response: false,
    status: '',
  });
  const [copyDialog, setCopyDialog] = useState<boolean>(false);

  const handleCopyDialog = () => setCopyDialog(false);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rows per page
  };

  const pageCount = Math.ceil(subscribers.length / rowsPerPage);

  return (
    <Stack
      height={'100%'}
      width={'100%'}
      alignItems={'center'}
      justifyItems={'center'}
      sx={{ padding: '20px 0', textAlign: 'center' }}
    >
      {subscribers.length === 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Typography fontSize={24}>
            You currently have no subscribers, start inviting your contacts.
          </Typography>
        </div>
      )}
      {subscribers.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <TableContainer sx={{ height: '100%', overflow: 'visible' }}>
            <Table
              stickyHeader
              sx={{
                minWidth: 650,
                maxHeight: '100%',
                borderCollapse: 'separate',
                borderSpacing: '0px 8px',
                height: '100%',
              }}
              aria-label="simple table"
            >
              <TableHeader isMobile={isMobile} />
              <TableBody>
                {subscribers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: SubscriberData, index: number) => (
                    <CustomTableRow
                      key={index}
                      index={index}
                      row={row}
                      isMobile={isMobile}
                      setCopyDialog={setCopyDialog}
                      setLink={setLink}
                    />
                  ))}
              </TableBody>
            </Table>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '12px',
                width: '100%',
              }}
            >
              <div />
              <Stack
                direction={'row'}
                marginLeft={'10%'}
                justifyContent={'center'}
                spacing={3}
              >
                <StyledIconButton
                  onClick={() => handleChangePage(null, page - 1)}
                  disabled={page === 0}
                >
                  <BackArrowIcon />
                </StyledIconButton>
                <StyledIconButton
                  onClick={() => handleChangePage(null, page + 1)}
                  disabled={page === pageCount - 1}
                >
                  <ForwardArrowIcon />
                </StyledIconButton>
              </Stack>

              <TablePagination
                sx={{ '& .MuiTablePagination-input': { display: 'none' } }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={subscribers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // labelDisplayedRows={({ page }) =>
                //     `Page ${page + 1} of ${pageCount}`
                // }
                labelRowsPerPage={''}
                nextIconButtonProps={{ style: { display: 'none' } }}
                backIconButtonProps={{ style: { display: 'none' } }}
                labelDisplayedRows={() => (
                  <>
                    Page&nbsp;
                    <InputBase
                      value={inputPage}
                      onChange={handleInputChange}
                      onKeyPress={handleInputKeyPress}
                      inputProps={{ min: 1, max: 1 }}
                      sx={{
                        width: '40px',
                        padding: '1px 2px',
                        fontSize: '1em',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        margin: 'auto',
                        textAlign: 'center',
                        paddingLeft: '20%',
                      }}
                    />
                    {' of '}
                    {pageCount}
                  </>
                )}
              />
            </div>
            <AlertDialog dialog={dialog} setDialog={setDialog} />
          </TableContainer>
        </div>
      )}

      <CopyLinkDialog
        isOpen={copyDialog}
        onClose={handleCopyDialog}
        title={'Copy User Profile'}
        description={'Copy user profile to clipboard!'}
        link={link}
      />
    </Stack>
  );
};
